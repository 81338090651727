html, body {
  height: 100%;
  margin: 0;
}

body {
  background: linear-gradient(270deg, #ff7e5f, #feb47b);
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.App-footer {
  width: 100%;
  padding: 10px;
  background-color: #f1f1f1;
  text-align: center;
  position: relative;
  bottom: 0;
}

.App-footer a {
  margin: 0 10px;
  text-decoration: none;
}

.policy-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  text-align: left;
}
